<template>
  <div
    class="flex h-25 rounded-sm border border-secondary-250 divide-x divide-x-reverse divide-secondary-250 bg-white">
    <div
      class="flex justify-between items-center space-x-2 space-x-reverse px-4 pe-8 w-25/100">
      <div class="grow">
        <div class="inline">
          <span class="line-clamp-2">
            <span class="whitespace-nowrap font-simplerBold">
              {{ `שיעור ${index + 1} -` }}
            </span>
            {{ title }}
          </span>
        </div>
        <div class="w-full flex gap-1 items-center">
          <img :src="amitCoin" class="w-5 h-5" alt="amitCoin" />
          <div class="w-full text-xs text-default">
            <span class="font-light">
              אפשר להרוויח פה
              <span class="font-simplerBold ps-1">
                {{ `${lessonCoins} אמיתקוין!` }}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="w-75/100">
      <BaseScrollbar
        :suppress-scroll-x="false"
        :suppress-scroll-y="true"
        :wheel-propagation="true">
        <div class="flex items-center justify-start pt-5 h-full">
          <div
            v-if="locked"
            class="w-full flex items-center justify-center gap-2">
            <LockLesson />
            <div>
              <div class="text-default font-bold">
                נראה שהשיעור עדיין לא נפתח
              </div>
              <span class="text-default font-light">נתראה ממש בקרוב :)</span>
            </div>
          </div>
          <div v-else class="w-full">
            <div v-if="progress < 100" class="h-full">
              <BaseTimeline class="px-10" :horizontal="true">
                <TimelineItem v-for="section in sections as any" :key="section">
                  <TimelinePoint :color="colorPoint(section.progress)">
                    <Check
                      v-if="checked(section.progress)"
                      color="white"
                      :size="12"/>
                  </TimelinePoint>
                  <TimelineContent>
                    <TimelineBody>
                      <div>
                        <div
                          class="line-clamp-3 break-words text-secondary-600 text-xs pe-2 w-22">
                          {{ section.title }}
                        </div>
                      </div>
                    </TimelineBody>
                  </TimelineContent>
                </TimelineItem>
              </BaseTimeline>
            </div>
            <div v-else class="h-full w-full items-center justify-center">
              <div class="w-full flex items-center justify-center gap-2">
                <DoneLesson />
                <div>
                  <div class="text-default font-bold">סיימת את השיעור!</div>

                  <span class="text-default font-light">והרווחת</span>
                  <span class="text-orange-500 font-bold pr-0.5">
                    {{ `${userCoins} אמיתקוין!` }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="progress === 100"
            class="w-18/100 flex items-center space-x-2 space-x-reverse justify-center hidden">
            <div
              class="flex flex-col space-y-1 cursor-pointer pt-1"
              @click="
                router.push({
                  name: 'course-home',
                })
              ">
              <div class="flex space-x-0.5 space-x-reverse text-sm">
                <div class="font-bold text-primary-500 pt-0.5">להתנסות שלי</div>
                <ArrowIcon class="stroke-primary-500 w-5" />
              </div>
            </div>
          </div>
        </div>
      </BaseScrollbar>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router';
import amitCoin from '/@/assets/amitCoin.svg?url';
import ArrowIcon from '/@/assets/navArrow.svg?component';
import LockLesson from '/@/assets/lockLesson.svg?component';
import DoneLesson from '/@/assets/doneLesson.svg?component';
import {
  BaseTimeline,
  TimelineItem,
  TimelinePoint,
  TimelineContent,
  TimelineBody,
  BaseScrollbar,
} from '@nwire/amit-design-system';
import { Check } from 'lucide-vue-next';

defineProps({
  index: {
    type: Number,
    default: 0,
  },
  title: {
    type: String,
    default: '',
  },
  progress: {
    type: Number,
    default: 0,
  },
  userCoins: {
    type: Number,
    default: 0,
  },
  lessonCoins: {
    type: Number,
    default: 0,
  },
  sections: {
    type: Array,
    default: () => [],
  },
  locked: {
    type: Boolean,
    default: false,
  },
  unlockAt: {
    type: String,
    default: '',
  },
});

const colorPoint = (progress: number) => {
  if (progress === 0) {
    return 'secondary-450';
  }
  if (progress === 100) {
    return 'blue-sea-950';
  }
  return 'primary';
};
const checked = (progress: number) => {
  if (progress === 100) {
    return true;
  }
  return false;
};
const router = useRouter();
</script>
