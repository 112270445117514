<template>
  <div :class="wrapperClasses">
    <div :class="pointClasses">
      <slot />
    </div>
    <div :class="borderClasses"></div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, useSlots } from 'vue';
import classNames from 'classnames';

const props = defineProps({
  color: {
    type: String,
    default: 'secondary-400',
  },
});

const slots = useSlots();

const hasSlot = computed(() => {
  return !!slots.default;
});

const isHorizontal = inject('horizontal');

const wrapperClasses = computed(() => {
  return classNames(isHorizontal ? 'flex items-center' : '');
});

const defaultBorderClasses =
  'h-0.5 w-full bg-gray-200 dark:bg-gray-700 sm:flex';
const borderClasses = computed(() =>
  classNames(defaultBorderClasses, `bg-${props.color}`, {
    'sm:hidden hidden': !isHorizontal,
  }),
);

const pointClasses = computed(() => {
  const defaultClasses = `absolute rounded-full -right-2.5 bg-${props.color}`;

  const verticalWithNoIconClasses = 'mt-0 w-4 h-4';
  const verticalWithIconClasses =
    'mt-0 w-4 h-4  flex justify-center items-center';
  const horizontalWithNoIconClasses = 'w-4 h-4';
  const horizontalWithIconClasses =
    'w-5 h-5 right-0 flex justify-center items-center ring-0 ring-white dark:ring-gray-900';

  const isVerticalWithNoIcon = !isHorizontal && !hasSlot.value;
  const isVerticalWithIcon = !isHorizontal && hasSlot.value;
  const isHorizontalWithNoIcon = isHorizontal && !hasSlot.value;
  const isHorizontalWithIcon = isHorizontal && hasSlot.value;

  return classNames(defaultClasses, {
    [verticalWithNoIconClasses]: isVerticalWithNoIcon,
    [verticalWithIconClasses]: isVerticalWithIcon,
    [horizontalWithNoIconClasses]: isHorizontalWithNoIcon,
    [horizontalWithIconClasses]: isHorizontalWithIcon,
  });
});
</script>
