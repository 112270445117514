<template>
  <div>
    <ol :class="timelineClasses" v-bind="$attrs">
      <slot></slot>
    </ol>
  </div>
</template>
<script lang="ts" setup>
import classNames from 'classnames';
import { computed, provide } from 'vue';

const props = defineProps({
  horizontal: {
    type: Boolean,
    default: false,
  },
});

provide('horizontal', props.horizontal);

const defaultClasses =
  'relative border-secondary-200 dark:border-secondary-300';
const verticalClasses = 'border-r-4';
const horizontalClasses = 'flex';

const timelineClasses = computed(() =>
  classNames(
    defaultClasses,
    props.horizontal ? horizontalClasses : verticalClasses,
  ),
);
</script>
