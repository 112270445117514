<template>
  <div class="text-secondary-900 text-3xl flex gap-3">
    <span class="font-anomaliaBold">{{ disciplines }}</span>
    <span class="font-anomaliaLight">- קורס</span>
    <span class="font-anomaliaLight">{{ courseStore.course.caption }}</span>
  </div>

  <div class="flex flex-col gap-2 pt-10">
    <div class="flex flex-col space-y-6">
      <BaseWidget density="none">
        <template #default>
          <div
            class="w-full h-40 rounded-md flex items-center p-10 bg-white space-x-4 space-x-reverse justify-between">
            <div class="flex">
              <ShoeIcon />
              <div class="text-secondary-900 pt-8">
                <div class="text-lg font-anomaliaBold">
                  {{ enrollmentState.title }}
                </div>
                <div class="text-base font-anomaliaLight pt-1">
                  לחזרה לנקודה בשיעור בה הפסקתי
                </div>
              </div>
            </div>

            <BaseButton
              color="none"
              class="h-full border-2 border-primary rounded"
              @click="router.push(enrollmentState.route)">
              <span class="text-primary font-anomaliaBold">
                {{ enrollmentState.action }}
              </span>
            </BaseButton>
          </div>
        </template>
      </BaseWidget>
      <div class="flex flex-col space-y-4">
        <div class="space-y-2 overflow-scroll bg-transparent scrollbar-hide">
          <LessonStatus
            v-for="(item, index) in currentEnrollmentFormat.lessons"
            :key="index"
            :index="index as any"
            :title="item.title"
            :user-coins="item.coins"
            :lesson-coins="lessonCoins[index]"
            :progress="item.progress"
            :lesson-id="item.id"
            :sections="item.sections"
            :locked="item.locked"
            :unlock_at="item.unlock_at"/>
        </div>
      </div>
    </div>
  </div>

  <SideWidget>
    <div class="bg-white w-full py-10 px-5 border-2 text-center">
      <RouterLink
        :to="{
          name: 'course-dashboard',
          params: { course: $route.params.course },
        }">
        <span class="uppercase test-lg">Switch to New Dashboard</span>
      </RouterLink>
    </div>
  </SideWidget>

  <SideWidget>
    <div v-if="showPersonas" class="flex flex-row w-full justify-between">
      <template v-for="(persona, key) in selectedPersonas">
        <div
          v-if="persona"
          :key="persona.id"
          class="w-47/100 h-40 flex flex-col justify-center bg-white border border-secondary-250 rounded-4 pr-6">
          <img
            v-if="persona.avatar"
            class="w-15 h-15 rounded-full object-cover mt-2"
            :src="persona.avatar"
            :alt="key"/>
          <div class="py-3">
            <div class="text-md font-anomaliaBold">{{ persona.name }}</div>
            <div class="text-sm font-anomaliaLight">
              {{ t(`personas.${key}.title`) }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </SideWidget>
  <SideWidget>
    <NotificationWidget />
  </SideWidget>
  <SideWidget>
    <ProjectWidget v-if="projectEnrollment" :enrollment="projectEnrollment" />
  </SideWidget>
</template>
<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useAccountStore } from '/@/store/account';
import { useCourseStore } from '/@/store/course';
import { BaseWidget, BaseButton } from '@nwire/amit-design-system';
import { formatLearningHistoryDetails } from '/@/services/accounts';
import { fetchProjectEnrollments } from '/@/services/projects';
import NotificationWidget from '/@/components/NotificationWidget.vue';
import fetchSkills from '/@/services/content';
import ShoeIcon from '/@/assets/shoe.svg?component';
import LessonStatus from '/@/components/LessonStatus.vue';
import { get, map, sumBy } from 'lodash';
import { useI18n } from 'vue-i18n';
import pluralize from 'pluralize';
import { SideWidget } from '@amit/layout';
import ProjectWidget from '/@/components/ProjectWidget.vue';
import { watchEffect } from 'vue';
import { IEnrollment } from '/@/types/interfaces';
const { t } = useI18n();
const accountStore = useAccountStore();
const courseStore = useCourseStore();
const router = useRouter();
const route = useRoute();
const selectedPersonas = ref({ assistant: {} as any, mentor: {} as any });
const dataPersonas = ref();
const projectEnrollment = ref();
const skilyId = '63a1001b6376e0feb759b966';
const currentCourse = computed(() => route.params.course as string);
const enrollment = computed(
  () =>
    accountStore.enrollments?.find(
      enrollment => enrollment.course.id === currentCourse.value,
    ) as any,
);
const currentEnrollment = computed(
  () => accountStore.getCurrentEnrollment(currentCourse.value) as IEnrollment,
);
const currentEnrollmentFormat: any = computed(() =>
  formatLearningHistoryDetails(currentEnrollment.value),
);
const currentLesson = computed(() =>
  accountStore.getCurrentLesson(currentEnrollment.value),
);

const projectId = computed(() => {
  const courseProject = enrollment.value?.course?.assessments?.find(
    assessment => assessment.type === 'project',
  );
  return courseProject?.reference || '';
});

const enrollmentState = computed(() => {
  if (enrollment.value?.course?.poll && enrollment.value?.status == 'pending') {
    return {
      title: 'יוצאים לדרך',
      action: 'בואו נתחיל בשיעור >',
      route: {
        name: 'onboarding', // should be generic "surveys"
        params: enrollment.value.course.poll,
      },
    };
  }
  const route =
    courseStore?.course?.theme?.landingPage?.image ||
    currentCourse.value === skilyId
      ? {
          name: 'course-home',
          params: { course: currentCourse.value },
        }
      : {
          name: 'skill-lesson',
          params: {
            lesson: currentLesson.value,
          },
        };

  return {
    title: 'ממשיכים בתנועה!',
    action: 'בואו נמשיך בשיעור >',
    route,
  };
});

const disciplines = computed(() =>
  courseStore.course.disciplines
    ? courseStore.course.disciplines.join(', ')
    : '',
);

const lessonCoins = computed(() => {
  return map(courseStore.course.outline, lesson => {
    return sumBy(lesson.sections, 'coins');
  });
});

const showPersonas = computed(() => {
  return (
    courseStore.course.caption === 'ניהול עצמי' &&
    selectedPersonas.value.assistant?.id
  );
});

const getPersonaData = type => {
  const personaId = get(accountStore, `enrollments[0][${type}]`, '');
  return dataPersonas.value[pluralize(type)].find(
    item => item.id === personaId,
  );
};

onMounted(() => {
  accountStore.setEnrollments(currentCourse.value);
  accountStore.getUser(true);
  const { onSuccess } = fetchSkills();
  onSuccess(({ data }) => {
    dataPersonas.value = data[0];
    selectedPersonas.value['mentor'] = getPersonaData('mentor');
    selectedPersonas.value['assistant'] = getPersonaData('assistant');
  });
});

watchEffect(() => {
  if (!projectId.value) {
    return;
  }
  const { onSuccess } = fetchProjectEnrollments(
    projectId.value,
    accountStore.user.id,
  );
  onSuccess(({ data }) => {
    if (data?.length) {
      projectEnrollment.value = data[0];
    }
  });
});
</script>
